$.extend(true, $.fn.dataTable.defaults, {
    columnDefs: [
        {
            targets: '_all',
            render: function(data, type, row, meta) {
                return he.encode((data || '') + '');
            }
        }
    ]
});

// Selectable
$(function () {
    if (!$(document).find('table.table-selectable').length) return;

    $(document).on('preInit.dt', function (ev, settings) {
        var table = $(ev.target),
            card = table.closest('.card'),
            search = $('[type=search]', card),
            api = table.DataTable(),
            cb1 = card.find('[data-toggle=select-all]'),
            r = cb1.closest('tr'),
            count = card.find('[data-toggle=select-count]'),
            countButton = count.closest('button'),
            selectedIds = $('[data-toggle=selected-ids]'),
            selectedAll = $('[data-toggle=selected-all]'),
            selectedSearch = $('[data-toggle=selected-search]'),
            getDisplayCount = function () {
                if (r.hasClass('selected'))
                    return api.page.info().recordsTotal;
                else
                    return api.rows({ selected: true }).count();
            },
            updateDisplayCount = function () {
                var c = getDisplayCount() || 0;

                count.each(function () {
                    var e = $(this),
                        format = e.attr('data-format') || '{0}'
                        ;

                    e.html(format.replace('{0}', c));
                });

                countButton.toggleClass('disabled', c == 0);
            },
            updateSelectionForm = function () {
                var ids = api.rows({ selected: true }).data().pluck('id').toArray().join('|');
                var all = r.hasClass('selected') ? 'true' : 'false';

                selectedIds.each(function () { $(this).val(ids); });
                selectedAll.each(function () { $(this).val(all); });
            },
            onCountChanged = function () {
                var count = api.rows({ selected: true }).count();

                // Ensure all is in sync
                if (count == 0) {
                    r.removeClass('selected');
                } else {
                    if (r.hasClass('selected')) {
                        if (count != api.rows().count()) {
                            r.removeClass('selected');
                        }
                    } else {
                        if (count == api.page.info().recordsTotal) {
                            r.addClass('selected');
                        }
                    }
                }

                updateDisplayCount();

                updateSelectionForm();
            }
            ;

        cb1.closest('th')
            .on('click', function () {
                r.toggleClass('selected');

                if (r.hasClass('selected'))
                    api.rows().select();
                else
                    api.rows().deselect();
            });

        search.on('input keyup', function () {
            selectedSearch.val($(this).val());
        });

        api
            .on('select', onCountChanged)
            .on('deselect', onCountChanged)
            .on('processing', onCountChanged)
            ;

        onCountChanged();
    });
});

// Row refresh
$(function () {
    var table = $('#table')
        .on('click', '[data-toggle=row-refresh]', function (ev) {
            var e = $(this),
                url = e.attr('href'),
                progress = e.attr('data-progress-text'),
                success = e.attr('data-success-text'),
                method = e.attr('data-method') || 'POST';

            if (!url) return;

            ev.preventDefault();

            if (progress) {
                $(document).Toasts('create', {
                    autohide: true,
                    title: progress,
                    delay: 5000,
                    icon: 'fas fa-sync',
                    class: 'bg-info'
                });
            }

            $.ajax({
                method: method,
                url: url,
                success: function (html) {
                    table.DataTable().ajax.reload();

                    if (success) {
                        $(document).Toasts('create', {
                            autohide: false,
                            title: success,
                            delay: 5000,
                            icon: 'fas fa-sync',
                            class: 'bg-success'
                        });
                    }
                },
                error: function (xhr, status, error) {
                    console.log({ xhrResponseText: xhr.responseText, xhrStatusText: xhr.statusText, status: status, error: error });

                    table.DataTable().ajax.reload();

                    $(document).Toasts('create', {
                        autohide: false,
                        title: 'Error occurred (' + xhr.status + '). Please refresh the page and try again.',
                        icon: 'fas fa-times-circle',
                        class: 'bg-danger'
                    });
                }
            });
        });
});